import React, { useState } from 'react';
import { HiCheckCircle } from "react-icons/hi";
import axios from "axios";

const CaptureForm = () => {
    const [formSubmission, setFormSubmission] = useState(false);
    const onFormSubmit = (e) => {
        e.preventDefault();
        const val = e.target[0].value;
        if (!val || val.length === 0) {
            alert('Please enter in a valid email address');
            return;
        }
        const formData = new FormData();
        formData.append("email", val);
        axios.post('https://account.formflare.io/0ae02543-704c-478a-56bf-08d93661b6c8', formData)
            .then(() => {
                setFormSubmission(true);
            })
            .catch(() => {
                alert('There was a problem submitting the form. Please try again.');
            });
    }

    return (
        <>
            {formSubmission ?
                <div className="flex items-center justify-center text-white leading-none"><HiCheckCircle className="text-green-500 mr-1 text-2xl" />You've been added. We'll be in touch soon!</div>
                :
                <div>
                    <form autoComplete="off" onSubmit={(e) => onFormSubmit(e)} method="POST" className="mt-1 lg:flex h-full rounded-md shadow-sm">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-indigo-200 opacity-25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </div>

                            <input type="email" name="email" id="email" autoComplete="off" className="captureFormInput text-white border-2 focus:ring focus:outline-none focus:shadow-none focus:border-indigo-200 focus:border-opacity-25 block w-full h-full rounded-md lg:rounded-l-md lg:rounded-r-none pl-10 text-base" placeholder="you@youremail.com" />

                        </div>
                        <button type="submit" className="-ml-px relative block w-full lg:w-auto items-center space-x-2 px-4 py-2 bg-indigo-700 border-2 border-transparent text-white text-base mt-1 lg:mt-0 rounded-md lg:rounded-r-md lg:rounded-l-none hover:bg-indigo-600 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                            <span>Get notified</span>
                        </button>
                    </form>
                </div>
            }
        </>
    )
}
export default CaptureForm;