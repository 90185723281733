import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { BiScreenshot, BiShapeSquare } from "react-icons/bi";
import { FiPlay, FiArrowRight, FiZap, FiFilm, FiSliders, FiFileText, FiPlayCircle } from "react-icons/fi";
import { BsPlayFill } from "react-icons/bs";
import { HiCheckCircle } from "react-icons/hi";
import { RiPlayCircleFill } from "react-icons/ri";
import { RoughNotation } from "react-rough-notation";
import { SquareDots, ArrowDrawn } from "../components/icons";
import HoverVideoPlayer from 'react-hover-video-player';
import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios';

import Layout from "../components/layout";
import heroBg from "../images/hero-bg2.jpg";
import heroBg3 from "../images/hero-bg3.jpg";
import SEO from "../components/seo";
import { graphql, useStaticQuery } from "gatsby";
import CaptureForm from "../components/captureForm";

export const query = graphql`
query VideoTemplates {
  templates: allGraphCmsTemplate(sort: {order: DESC, fields: updatedAt}, limit: 6) {
    nodes {
      poster {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 600, quality: 70)
          }
        }
      }
      videoPreview
    }
  }
}
`;

const hd_features = [
  '1920x1080p resolution',
  <>Fast Renders <span className="text-sm">(usually delivered within 15mins)</span></>,
  <Link to="/license">Commercial License</Link>,
  'ProRes (.mov) & H264 (.mp4) file formats',
];

const uhd_features = [
  '3840x2160p resolution',
  <>Fast Renders <span className="text-sm">(usually delivered within 15mins)</span></>,
  <Link to="/license">Commercial License</Link>,
  'ProRes (.mov) & H264 (.mp4) file formats',
];

const curLabel = (currency = "GBP") => {
  const currencies = {
    "GBP": "£",
    "USD": "$",
    "EUR": "€"
  };
  return currencies[currency];
}

const IndexPage = ({location}) => {
  const templates = useStaticQuery(query).templates.nodes;
  let [open, setOpen] = useState(false);
  let [prices, setPrices] = useState({});
  const cancelButtonRef = useRef();

  useEffect(() => {
    const url = process.env.GATSBY_MOCKUP_API + '/pricing/';
    const params = { product_ids: process.env.GATSBY_PRODUCT_HD_ID + ',' + process.env.GATSBY_PRODUCT_4K_ID };
    axios(url, { params })
      .then((res) => {
        const products = res.data.response.products;
        const hd = products.find(x => x.product_id == process.env.GATSBY_PRODUCT_HD_ID);
        const uhd = products.find(x => x.product_id == process.env.GATSBY_PRODUCT_4K_ID);
        const hdPrice = curLabel(hd.currency) + hd.price.gross;
        const uhdPrice = curLabel(uhd.currency) + uhd.price.gross;
        const product_prices = {
          'hd': hdPrice,
          'uhd': uhdPrice
        }
        setPrices(product_prices);
      });
  }, []);

  return (
    <>
      <Layout>
        <SEO
          keywords={[`mockup`, `clips`, `mockupclips`, `stock`, `video`, `maker`, `editor`, `online`, `app`, `device`, `phone`, `tablet`, `desktop`, `screen`, `replacement`, `iphone`, `macbook`, `laptop`, `samsung`, `phone`]}
          title="Stock Video Mockup Maker - Mockupclips.com"
        />

        <section className="bg-gradient-to-l from-blue-500 to-blue-700 relative bg-cover" style={{ backgroundImage: `url('${heroBg}')`, backgroundPosition: '100%' }}>

          <div className="container mx-auto pt-28 relative z-10">
            <div className="mx-auto w-full px-6 md:px-0 md:w-4/5 lg:w-2/3">
              <h1 className="text-center text-white font-semibold text-3xl md:text-5xl leading-tight mb-4">Create stunning <span style={{ backgroundColor: '#131824' }} className="px-4 py-2 leading-none text-white rounded inline-block">video mockups</span> in just a few clicks.</h1>
              <h2 className="w-full px-0 md:px-6 md:w-3/4 mx-auto text-center text-base md:text-xl text-indigo-300">Place your media into professional 4K stock videos.<br /> Edit &amp; render directly in the browser.</h2>

              <div className="relative mb-12">

                <div className="mt-10">
                  <div className="flex flex-row flex-wrap justify-center">
                    <button onClick={() => setOpen(true)} className="sm:mr-4 flex items-center w-full justify-center sm:w-auto md:inline-flex bg-white bg-opacity-15 text-white border border-white border-opacity-25 h-12 px-5 focus:outline-none hover:bg-opacity-100 hover:text-blue-500 rounded transition duration-200"><FiPlay className="mr-1" />Watch Overview</button>
                    <a className="mt-2 sm:mt-0 flex w-full items-center justify-center sm:w-auto md:inline-flex text-white transition duration-150 bg-indigo-700 h-12 px-5 focus:outline-none hover:bg-indigo-600 rounded border border-indigo-600" href="https://app.mockupclips.com">Create Mockup Now</a>
                  </div>

                  <div className="tryWithoutSigning absolute">
                    <div className="absolute text-xs top-0 z-10 text-indigo-200 text-opacity-50 transform -scale-x-1 rotate-12" style={{ right: '4px', top: '-5px' }}><ArrowDrawn className="h-10 text-indigo-200 fill-current opacity-50" /></div>
                    <div className="absolute handwritten text-base right-0 top-0 z-10 text-indigo-200 text-opacity-50 transform rotate-12" style={{ right: 62, top: '-18px' }}>Try without signing up</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto w-full px-6 md:px-0 md:w-4/5">
              <div className="flex flex-row items-center h-8 px-2 bg-gray-300 bg-opacity-15 rounded-t-md border border-white border-opacity-25">
                <div className="h-3 w-3 rounded-full bg-red-500 mr-1.5"></div>
                <div className="h-3 w-3 rounded-full bg-orange-500 mr-1.5"></div>
                <div className="h-3 w-3 rounded-full bg-green-500"></div>
              </div>
              <div className="rounded-b-md flex items-center justify-center cursor-pointer relative" onClick={() => setOpen(true)}>
                <div className="absolute z-10">
                  <div className="h-28 w-28 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-85"><BsPlayFill className="text-indigo-100" style={{ fontSize: '3rem' }} /></div>
                </div>
                <video className="w-full h-full rounded-b-md" autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} poster={'/videos/walkthrough-ss.jpg'} >
                  <source src={'/videos/walkthrough.mp4'} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          {/* <div className="bg-svg-shape-1"></div> */}
          <div className="relative page-header-wave">
            <svg style={{ minHeight: '27vw' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290" preserveAspectRatio="xMinYMid slice">
              <path fill="#f7fafc" fillOpacity="1" d="M0,288L1440,160L1440,320L0,320Z"></path>
            </svg>
          </div>
        </section>

        <section className="bg-gray-100 lg:pt-32 pt-16">
          <div className="container mx-auto pt-10 md:pt-24 lg:pt-16 xl:pt-40 pb-18 md:pb-28 px-6">
            <div className="flex flex-row flex-wrap -mx-8">
              <div className="relative w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
                <video className="relative z-20 rounded shadow-xl mb-4" muted={true} autoPlay={true} loop={true} playsInline={true}>
                  <source src="/videos/site-compilation.mp4" type="video/mp4" />
                </video>
                <div className="absolute transform scale-50 z-10" style={{ top: -71.5, left: -85 }}>
                  <SquareDots className="text-indigo-600" />
                </div>
                <div className="hidden md:block absolute border rounded border-indigo-600 border-opacity-50" style={{ top: 35, left: 0, height: 300, width: '88%' }}></div>
              </div>
              <div className="w-full lg:w-1/2 px-8">
                <div className="flex flex-wrap pt-0 md:pt-4">
                  <div>
                    <h3 className="text-2xl md:text-4xl gradient-text leading-tight text-gray-800 font-semibold mb-5">Easily place your image into professional 4K stock videos</h3>
                    <ul className="space-y-1.5 text-lg text-gray-700 leading-relaxed text-opacity-75">
                      <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" /><span className="font-bold text-indigo-900">Real-time playback</span> in your browser</li>
                      <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" /><span className="font-bold text-indigo-900">Precise object tracking</span> for realistic results</li>
                      <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Full control over <span className="font-bold text-indigo-900">reflections &amp; colour settings</span></li>
                      <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Royalty-free <span className="font-bold text-indigo-900">commercial license</span></li>
                      <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Export to high-quality <span className="font-bold text-indigo-900">4K</span> or <span className="font-bold text-indigo-900">Full HD</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="container px-6 pb-24 mx-auto">
              <div className="flex flex-wrap -m-4">
                  <div className="p-4 md:w-1/2 w-full">
                      <div className="h-full bg-white border-2 border-indigo-600 p-8 rounded shadow">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                              className="block w-5 h-5 text-indigo-600 mb-4" viewBox="0 0 975.036 975.036">
                              <path
                                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
                          </svg>
                          <p className="text-gray-600 leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday
                              carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo,
                              post-ironic heirloom try-hard pabst authentic iceland.</p>
                          <a className="inline-flex items-center">
                              <img alt="testimonial" src="https://dummyimage.com/106x106"
                                  className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center" />
                              <span className="flex-grow flex flex-col pl-4"><span
                                      className="font-semibold text-indigo-700">Dan Gibbons</span><span
                                      className="text-gray-500 text-sm">Company Director - Yellow Lizard Media</span></span></a>
                      </div>
                  </div>
                  <div className="p-4 md:w-1/2 w-full">
                      <div className="h-full bg-white border-2 border-indigo-600 p-8 rounded shadow"><svg xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor" className="block w-5 h-5 text-indigo-600 mb-4" viewBox="0 0 975.036 975.036">
                              <path
                                  d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
                          </svg>
                          <p className="text-gray-600 leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday
                              carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo,
                              post-ironic heirloom try-hard pabst authentic iceland.</p><a
                              className="inline-flex items-center"><img alt="testimonial" src="https://dummyimage.com/107x107"
                                  className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center" /><span
                                  className="flex-grow flex flex-col pl-4"><span
                                      className="font-semibold text-indigo-700">Alper Kamu</span><span
                                      className="text-gray-500 text-sm">DESIGNER</span></span></a>
                      </div>
                  </div>
              </div>
          </div> */}

        </section>

        <section className="bg-cover" style={{ backgroundImage: `url('${heroBg3}')`, backgroundPosition: '100%' }}>
          <div className="container mx-auto pt-16 md:pt-20 pb-6 px-6">
            <div className="w-full mx-auto md:text-center mb-8 md:mb-12">
              <h3 className="text-2xl md:text-4xl leading-tight text-white font-semibold">High quality video library.<br />Royalty-free &amp; ready for commercial use.</h3>
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
              {templates.map((template, index) => (
                <div key={template + index} className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                  <HoverVideoPlayer
                    key={index}
                    sizingMode="overlay"
                    unloadVideoOnPaused={true}
                    restartOnPaused={true}
                    preload="none"
                    videoSrc={template.videoPreview}
                    pausedOverlay={
                      <div className="relative">
                        <div className="absolute flex items-center justify-center h-full w-full">
                          <div><RiPlayCircleFill className="text-white text-opacity-85 text-6xl" style={{filter: 'drop-shadow(0px -0 2px rgba(0,0,0,0.25))'}}/></div>
                        </div>
                        <picture className="w-full rounded cursor-pointer">
                          <source srcSet={template.poster.localFile.childImageSharp.gatsbyImageData.images.sources[0].srcSet} type="image/webp" />
                          <source srcSet={template.poster.localFile.childImageSharp.gatsbyImageData.images.fallback.srcSet} type="image/jpeg" />
                          <img src={template.poster.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                        </picture>
                      </div>
                    }
                    className="videoPlayer cursor-pointer rounded m-0.5"
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <a href="https://app.mockupclips.com" className="inline-flex text-white bg-indigo-700 h-12 px-5 focus:outline-none hover:bg-indigo-600 rounded items-center border border-transparent">View All Templates<FiArrowRight className="ml-1" /></a>
            </div>

          </div>
        </section>

        <section style={{ background: `url('${heroBg}')`, backgroundSize: 'cover' }}>
          <div className="container mx-auto md:pt-12 pb-0 sm:pb-24 md:pb-52 px-6">
            <div className="w-full lg:w-1/2 mx-auto md:text-center mb-6 md:mb-12">
              <h3 className="text-2xl md:text-4xl leading-tight text-white font-semibold">Real-time playback and customization options</h3>
            </div>

            <div className="flex flex-wrap sm:-mx-2 mb-6">
              <div className="w-full lg:w-2/5 mb-8 md:mb-0 md:px-2 text-indigo-200 z-20">
                <div className="bg-indigo-300 bg-opacity-10 p-6 rounded-lg lg:mt-8 text-white text-opacity-85 border-2 border-white border-opacity-10">
                  <p className="mb-6">Instantly view a 360p video preview and make changes directly inside the browser.</p><p className="">No more waiting for renders to finish. Tweak reflection and color settings immediately for precise control. Changed your mind and want to use a different template? Quickly switch between them whilst retaining your uploaded media and purchase when you're ready.</p></div>
              </div>
              <div className="w-full lg:w-3/5 md:pl-2 pr-4">
                <div className="relative w-full z-20" style={{ transform: 'perspective(1200px) rotateY(-20deg) rotateX(5deg)' }}>
                  <video className="relative z-10 rounded shadow-xl mb-4" muted={true} autoPlay={true} loop={true} playsInline={true}>
                    <source src="/videos/editor-demo.mp4" type="video/mp4" />
                  </video>
                  <div className="hidden md:block absolute bg-gradient-to-b from-transparent to-indigo-600 opacity-35 rounded" style={{ top: 90, right: -25, height: 300, width: '100%' }}></div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative page-header-wave" style={{ marginTop: '-37vw' }}>
            <svg style={{ minHeight: '27vw' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290" preserveAspectRatio="xMinYMid slice">
              <path fill="#ffffff" fillOpacity="1" d="M0,288L1440,160L1440,320L0,320Z"></path>
            </svg>
          </div>
        </section>

        {/* <section className="relative z-20">
        <div className="container mx-auto py-20 px-6">
          <div className="flex flex-row flex-wrap -mx-8">
            <div className="relative w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
              <video className="relative z-10 rounded shadow-xl mb-4" muted={true} autoPlay={true} loop={true} playsInline={true}>
                <source src="https://d14pr3cu5atb0x.cloudfront.net/cms/1920x1080_Final_2020_Homepage%20Video-36f138a5ab.mp4" type="video/mp4" />
              </video>
              <div className="absolute w-full" style={{ top: 6, left: 50, transform: 'scale(1.4)' }}>
                <Blob1 className="hidden text-indigo-200 fill-current transform rotate-12 opacity-50" />
              </div>
              <div className="absolute w-20 h-3/5 border-2 border-purple-200 rounded" style={{ top: '-40px', left: '-30px' }} />
              <div className="absolute bg-gradient-to-b from-transparent to-purple-200 rounded" style={{ top: 35, left: 0, height: 300, width: '88%' }}></div>
            </div>
            <div className="w-full lg:w-1/2 px-8">
              <div className="flex flex-wrap pt-0 md:pt-4">
                <div>
                  <h3 className="text-4xl gradient-text leading-tight text-gray-800 font-semibold mb-5">Optionally generate a screenshot from your website</h3>
                  <p className="text-lg text-gray-700 leading-relaxed text-opacity-75">If you don't have an image to upload, you can easily generate a screenshot from your website. Just provide us a URL, and our tool will take a screenshot of your site and place it into your scene automatically.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        {/* <section className="bg-gray-100 relative z-20">
        <div className="container mx-auto py-20 px-6">
          <div className="flex flex-row flex-wrap -mx-8">
            <div className="w-full lg:w-1/2 px-8">
              <div className="flex flex-wrap pt-0 md:pt-4">
                <div>
                  <h3 className="text-4xl gradient-text leading-tight text-gray-800 font-semibold mb-5">Easily apply browser and operating system overlays</h3>
                  <p className="text-lg text-gray-700 leading-relaxed text-opacity-75">Culpa voluptate aute minim mollit enim ut adipisicing incididunt Lorem id cillum cupidatat esse excepteur. Sit Lorem laboris pariatur aute amet pariatur. Nulla consectetur aliqua adipisicing sunt magna nisi esse quis minim.</p>
                </div>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
              <video className="relative z-10 rounded shadow-xl mb-4" muted={true} autoPlay={true} loop={true} playsInline={true}>
                <source src="https://d14pr3cu5atb0x.cloudfront.net/cms/1920x1080_Final_2020_Homepage%20Video-36f138a5ab.mp4" type="video/mp4" />
              </video>
              <div className="absolute w-20 h-3/5 border-2 border-teal-200 rounded" style={{ top: '-40px', right: '-30px' }} />
              <div className="absolute bg-gradient-to-b from-transparent to-teal-200 rounded" style={{ top: 35, right: 0, height: 300, width: '88%' }}></div>
            </div>
          </div>
        </div>
      </section> */}

        <section className="bg-gradient-to-t from-gray-100 xl:pt-8 lg:pt-16 pt-8">
          <div className="container px-5 pt-10 pb-5 md:pb-12 mx-auto">

            <h3 className="text-2xl md:text-4xl w-full md:w-3/5 mx-auto font-semibold text-center text-gray-900 leading-tight mb-8">Features</h3>
            <div className="flex flex-wrap sm:-mx-4 mb-6">
              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-pink-800 bg-gradient-to-r from-red-600 to-pink-800 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <FiZap />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">Real-Time Playback</h4>
                  <p className="text-gray-700 leading-relaxed">Fast, low-res previews directly in your browser</p>
                </div>
              </div>
              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-gray-900 bg-gradient-to-r from-gray-700 to-gray-900 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <BiShapeSquare />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">Precise Object Tracking</h4>
                  <p className="text-gray-700 leading-relaxed">Tailored motion tracking data for realistic results</p>
                </div>
              </div>
              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-teal-500 bg-gradient-to-r from-green-500 to-teal-500 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <FiFilm />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">HD &amp; 4K Output</h4>
                  <p className="text-gray-700 leading-relaxed">Render high-quality ProRes and H264 video files</p>
                </div>
              </div>
              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-orange-500 bg-gradient-to-l from-red-500 to-orange-500 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <BiScreenshot />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">Capture Screenshot</h4>
                  <p className="text-gray-700 leading-relaxed">Optionally provide your website URL to auto generate an image.</p>
                </div>
              </div>

              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-blue-600 bg-gradient-to-l from-blue-700 to-blue-500 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <FiSliders />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">Reflections &amp; Color Control</h4>
                  <p className="text-gray-700 leading-relaxed">Instantly adjust visual settings with live preview</p>
                </div>
              </div>

              <div className="p-4 w-full md:w-1/2 lg:w-1/3 md:mb-3 mb-0 flex">
                <div className="w-16 h-16 bg-purple-700 bg-gradient-to-r from-purple-600 to-purple-800 text-2xl inline-flex items-center justify-center rounded-md shadow text-white mb-4 flex-shrink-0">
                  <FiFileText />
                </div>
                <div className="flex-grow pl-6">
                  <h4 className="text-gray-800 text-xl font-medium">Commercial License</h4>
                  <p className="text-gray-700 leading-relaxed">Royalty-free use of your final video render</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        {/** Pricing */}
        <section className="bg-cover" style={{ backgroundImage: `url('${heroBg}')`, backgroundPosition: '100%' }}>
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                <h3 className="text-white text-2xl md:text-4xl w-full md:w-3/5 mx-auto font-semibold text-center leading-tight mb-4">Pricing per video</h3>
                <p className="text-xl text-gray-300">
                  Choose a resolution when exporting your video. Both come with a royalty-free, commercial license.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-28">
            <div className="relative">
              <div className="absolute inset-0 h-3/4" />
              <div className="container relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 py-8 bg-white bg-opacity-15 sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className={`inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-gray-200 text-gray-400 bg-opacity-10`}>
                          High Definition
                        </h3>
                      </div>
                      <div className="text-white mt-4 flex items-baseline text-6xl font-extrabold">
                        {prices && prices.hd}
                        <span className="ml-0.5 text-sm font-medium text-gray-600">incl VAT</span>

                      </div>
                      <p className="text-lg text-gray-400">Perfect for websites, presentations and more</p>
                    </div>
                    <div className="bg-gray-100 bg-opacity-25 flex-1 flex flex-col justify-between px-6 py-6 space-y-6 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                        {hd_features.map((feature, i) => (
                          <li key={'hd-' + i} className="flex items-start">
                            <div className="flex-shrink-0">
                              <HiCheckCircle className="h-6 w-6 text-green-500" aria-hidden="true" />
                            </div>
                            <p className="ml-3 text-base text-gray-200">{feature}</p>
                          </li>
                        ))}
                      </ul>
                      <div className="w-full rounded-md">
                        <a href="https://app.mockupclips.com" className="flex justify-center w-full text-white bg-white bg-opacity-15 hover:bg-opacity-25 h-12 px-5 focus:outline-none rounded items-center border border-transparent">Get Started Now<FiArrowRight className="ml-1" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                      <div>
                        <h3
                          className={`inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-orange-200 text-orange-600`}>
                          4K Ultra HD
                        </h3>
                      </div>
                      <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                        {prices && prices.uhd}
                        <span className="ml-0.5 text-sm font-medium text-gray-600">incl VAT</span>
                      </div>
                      <p className="text-lg text-gray-600">Ideal for professionals looking for maximum video quality</p>
                    </div>
                    <div className="bg-gray-100 flex-1 flex flex-col justify-between px-6 py-6 space-y-6 sm:p-10 sm:pt-6">
                      <ul className="space-y-4">
                        {uhd_features.map((feature, i) => (
                          <li key={'4k-' + i} className="flex items-start">
                            <div className="flex-shrink-0">
                              <HiCheckCircle className="h-6 w-6 text-green-500" aria-hidden="true" />
                            </div>
                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                          </li>
                        ))}
                      </ul>
                      <div className="w-full rounded-md shadow">
                        <a href="https://app.mockupclips.com" className="flex justify-center w-full text-white bg-indigo-700 h-12 px-5 focus:outline-none hover:bg-indigo-600 rounded items-center border border-transparent">Get Started Now<FiArrowRight className="ml-1" /></a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-12" style={{ background: 'linear-gradient(to bottom, #000, #100e1b)' }}>
          <div className="container mx-auto px-8">
            <div className="mx-auto w-full md:w-104">
              <h3 className="text-3xl text-white font-bold text-center leading-snug mb-3.5">Stay up to date with new features &amp; announcements</h3>
              <CaptureForm />
            </div>
          </div>
        </section>

        {/* <section className="bg-gradient-to-l from-blue-500 to-blue-700 relative">
        <div className="container mx-auto py-16 px-6 relative z-10">
          <h3 className="text-4xl text-center leading-tight text-white font-semibold mb-6">How to get started</h3>
          <div className="steps-row flex flex-wrap flex-row -mx-4">
            <div className="steps-dot w-full md:w-1/4 px-4">
              <div className="text-center">
                <h4 className="text-6xl leading-tight font-bold text-white text-opacity-100">1</h4>
                <a href="#" className="text-white font-semibold inline-block text-xl border-b-2 border-dashed border-blue-300">Launch Editor</a>
                <p className="mt-1 text-base text-white text-opacity-50">Start from scrach or load in a designed template</p>
              </div>
            </div>
            <div className="steps-dot w-full md:w-1/4 px-4">
              <div className="text-center">
                <h4 className="text-6xl leading-tight font-bold text-white text-opacity-75">2</h4>
                <h5 className="text-white font-semibold text-xl">Add Slides</h5>
                <p className="mt-1 text-base text-white text-opacity-50">Select slide animations to your timeline</p>
              </div>
            </div>
            <div className="steps-dot w-full md:w-1/4 px-4">
              <div className="text-center">
                <h4 className="text-6xl leading-tight font-bold text-white text-opacity-50">3</h4>
                <h5 className="text-white font-semibold text-xl">Customize</h5>
                <p className="mt-1 text-base text-white text-opacity-50">Edit the text, images, colors &amp; more on each slide</p>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-4">
              <div className="text-center">
                <h4 className="text-6xl leading-tight font-bold text-white text-opacity-25">4</h4>
                <h5 className="text-white font-semibold text-xl">Render &amp; Share</h5>
                <p className="mt-1 text-base text-white text-opacity-50">Export your video in minutes &amp; share with the world</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-svg-shape-1" style={{ opacity: 0.14 }}></div>
      </section> */}

        {/* <CTA /> */}

      </Layout>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center sm:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-50"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full max-w-210">
                <div className="bg-white p-6 pb-0">
                  <div className="aspect-w-16 aspect-h-9">
                    <iframe src="https://www.youtube.com/embed/EAXxVbo_2do?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default IndexPage;
